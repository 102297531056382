import moment from "moment";
import 'moment/locale/fr';

export class DateHelperV3 {
  private dateMoment: moment.Moment = moment();

  // default 'YYYY-MM-DD HH:mm:ss'
  constructor(date: string | number = "", format = "YYYY-MM-DD HH:mm:ss") {
    //moment.locale('fr');

    if ("0000-00-00 00:00:00" === date) {
      return;
    }

    if (format === "unix" && typeof date === "number") {
      this.dateMoment = moment.unix(date).locale("fr");
      return;
    }

    if (date !== "" && format !== "") {
      this.dateMoment = moment(date, format);
    }

    if (!this.dateMoment.isValid()) {
      throw new Error("Date non valide");
    }
  }

  static generateTodayDate(formatOuput = "YYYY-MM-DD HH:mm:ss"): string {
    const today = moment();

    return today.format(formatOuput);
  }

  public istItPast() {
    return this.dateMoment.isBefore(moment(), "day");
  }

  public istItFutur() {
    return this.dateMoment.isAfter(moment(), "day");
  }

  public istItToday() {
    return this.dateMoment.isSame(moment(), "day");
  }

  public istAfterNow() {
    return this.dateMoment.isAfter(moment());
  }

  public isBeforeNow() {
    return this.dateMoment.isBefore(moment());
  }

  public weekDays() {
    return moment.weekdays(true);
  }

  public unixToLocal(dateUnix: number) {
    return moment.unix(dateUnix).utc();
  }

  public istItSameMonth(date:string, dateSelected:string) {
    return moment(date).isSame(moment(dateSelected), 'month');
  } 
  
  public addMonth(date: any, formatInput = "DD/MM/YYYY") {
    return moment(date, "ddd DD MMMM").add("1", "M").format(formatInput);
  }

  public getDateFromNow(date: any) {
    return moment(date, "ddd DD MMMM").startOf("day").fromNow();
  }

  public setEndofDate(date:string, dateEnd = 'day', format='YYYY-MM-DD HH:mm:ss')
  {
    return moment(date).endOf(dateEnd as moment.unitOfTime.StartOf).format(format);
  }

  // 'years'
  // 'months'
  // 'days'
  // 'hours'
  // 'minutes'
  // 'seconds'

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public modifyTimeDate(value = 1, type: any = "days") {
    this.dateMoment = this.dateMoment.add(value, type);
  }

  public getDateFormat(formatOutput: string) {
    return this.dateMoment.format(formatOutput);
  }

  public getDatePickerFormat() {
    return this.dateMoment.format();
  }

  public getDateYear() {
    return moment().diff(this.dateMoment, "years");
  }

  static calculDaysFromToday(date: string, format = "DD/MM/YYYY HH:mm:ss") {
    const today = moment();

    const end = moment(date, format);

    const duration = today.diff(end, "days");

    return duration;
  }

  static calculClosestDayToToday(date: string, format = "DD/MM/YYYY  HH:mm:ss") {
    const today = moment();

    const diff = moment(date, format).diff(today, "days");

    return diff;
  }

  /*
   * STATIC
   */
  static formatDate(date: string, format: string) {
    return moment(date).format(format);
  }

  static cloneDate(date: string) {
    return moment(date).clone();
  }

  static startOfMonth(date: string) {
    return moment(date).startOf('month');
  }

  static endOfMonth(date: string, formatOutput = "DD/MM/YYYY HH:mm:ss") {
    if (date === "0000-00-00 00:00:00") return "";
    
    const endOfMonth = moment(date).endOf('month');
    return endOfMonth.format(formatOutput)
  }

  static isBefore(dateA: string, dateB: string) {
    return moment(dateA).isBefore(dateB);
  }

  static daysInMonth(date: string) {
    return moment(date).daysInMonth();
  }

  static getDateDirectly(date: any, formatOutput = "DD/MM/YYYY HH:mm:ss") {
    if (date === "0000-00-00 00:00:00" || !date) return "";
     date  =  moment(date).locale('fr');
    try {
      const dateHelper = new DateHelperV3(date, "YYYY-MM-DD HH:mm:ss");

      const dateClean = dateHelper.getDateFormat(formatOutput);
      return dateClean;
    } catch (Exception) {
      return "";
    }
  }

  static getDateDirectlyWithFormatInput(
    date: any,
    formatOutput = "DD/MM/YYYY HH:mm:ss",
    formatInput = "DD/MM/YYYY HH:mm:ss"
  ) {
    if (date === "0000-00-00 00:00:00") return "";
    try {
      const dateHelper = new DateHelperV3(date, formatInput);

      const dateClean = dateHelper.getDateFormat(formatOutput);
      return dateClean;
    } catch (Exception) {
      return "";
    }
  }

  static getDateDirectlyBirthDate(date: any, formatOutput = "DD/MM/YYYY") {
    if (date === "0000-00-00 00:00:00") return "";

    try {
      const dateHelper = new DateHelperV3(date);

      const dateClean = dateHelper.getDateFormat(formatOutput);
      return dateClean;
    } catch (Exception) {
      return "";
    }
  }

  static getDay(date: any) {
    if (date === "0000-00-00 00:00:00") return 8;
    try {
      const day = moment(date).isoWeekday();

      return day;
    } catch (Exception) {
      return "";
    }
  }

  static compareDate(dateA: any, dateB: any) {
    dateA = moment(dateA);
    dateB = moment(dateB);

    if (dateA.diff(dateB) > 0) return 1;

    if (dateA.diff(dateB) < 0) return -1;

    return 0;
  }

  static compareTwoDates(dateAInit: any, dateBInit: any, format = "DD/MM/YYYY") {
    const dateA = moment(dateAInit, format);
    const dateB = moment(dateBInit, format);

    if (dateA.diff(dateB, "days") > 0) return 1;

    if (dateA.diff(dateB, "days") < 0) return -1;

    return 0;
  }

  static compareTwoDatesV2(dateAInit: any, dateBInit: any, format = "DD/MM/YYYY") {
    const dateA = moment(dateAInit, format);
    const dateB = moment(dateBInit, format);

    return dateA.diff(dateB);
  }

  static isSameDayUnix(dateA: number, dateB: number) {
    const dateAmoment = moment.unix(dateA).locale("fr");
    const dateBmoment = moment.unix(dateB).locale("fr");

    return dateAmoment.isSame(dateBmoment, "day");
  }

  static isSameMonthUnix(dateA: number, dateB: number) {
    const dateAmoment = moment.unix(dateA).locale("fr");
    const dateBmoment = moment.unix(dateB).locale("fr");

    return dateAmoment.isSame(dateBmoment, "month");
  }

  static compareDateUnix(dateA: number, dateB: number) {
    const dateAmoment = moment.unix(dateA).locale("fr");
    const dateBmoment = moment.unix(dateB).locale("fr");

    if (dateAmoment.diff(dateBmoment, "minutes") > 0) return 1;

    if (dateAmoment.diff(dateBmoment, "minutes") < 0) return -1;

    return 0;
  }

  static getIsItFuturDirectly(date: string) {
    try {
      const dateHelper = new DateHelperV3(date, "YYYY-MM-DD HH:mm:ss");

      return dateHelper.istItFutur();
    } catch (Exception) {
      return false;
    }
  }
  
  static getIsItToday(date: string) {
    try {
      const dateHelper = new DateHelperV3(date, "YYYY-MM-DD HH:mm:ss");

      return dateHelper.dateMoment.isSame(new Date(), "day");
    } catch (Exception) {
      return false;
    }
  }

  /** UNIX TO LOCAL  */
  static UnixToLocal(dateUnix: number, formatOutput = "DD/MM/YYYY") {
    try {
      const dateHelper = new DateHelperV3();
      const date = dateHelper.unixToLocal(dateUnix).format(formatOutput);
      return date;
    } catch (Exception) {
      return false;
    }
  }

  static getAllDateOfWeek(date: any, formatInput = "DD/MM/YYYY", formatOutput = "DD/MM/YYYY") {
    if (date === "0000-00-00 00:00:00") return "";
    try {
      const weekStart = moment(date, formatInput).clone().startOf("week");

      const days: string[] = [];
      for (let i = 0; i <= 6; i++) {
        days.push(moment(weekStart).add(i, "days").format(formatOutput));
      }

      return days;
    } catch (Exception) {
      return "";
    }
  }

  static getHourDuration(endTime: any, startTime: any) {
    const end = moment(endTime);
    const start = moment(startTime);
    const duration = end.diff(start, "hours");

    return duration;
  }

  static getHourAndMinutesDuration(endTime: any, startTime: any) {
    const end = moment(endTime);
    const start = moment(startTime);

    // calculate total duration
    const duration = moment.duration(end.diff(start));

    let label = duration.hours() + "h";

    if (duration.minutes() > 0) label += " " + duration.minutes() + "m";

    return label;
  }

  static isBetween(dateA: string, dateB: string, dateC: string, format = "ddd DD MMMM") {
    try {
      const compareDate = moment(dateA, format);
      const startDate = moment(dateB, format);
      const endDate = moment(dateC, format);

      return compareDate.isBetween(startDate, endDate, "day", "[]");
    } catch (Exception) {
      return false;
    }
  }

  /** MAX MOMENT FROM ARRAY */
  static MaxMoment(datetimes: any, format = "DD/MM/YYYY HH:mm:ss") {
    if (!datetimes) return;
    const dateHelper = new DateHelperV3();
    const moments = datetimes.map((d: any) => dateHelper.unixToLocal(d));
    const maxDate = moment.max(moments);
    return maxDate.format(format);
  }

  /** MIN MOMENT FROM ARRAY */
  static MinMoment(datetimes: any, format = "DD/MM/YYYY HH:mm:ss") {
    if (!datetimes) return;
    const dateHelper = new DateHelperV3();
    const moments = datetimes.map((d: any) => dateHelper.unixToLocal(d));
    const minDate = moment.min(moments);
    return minDate.format(format);
  }

  static dateFormatter(params: any, format = "DD/MM/YYYY") {
    if (
      params.value != "" &&
      params.value != "0000-00-00 00:00:00" &&
      params.value != undefined
    )
      return DateHelperV3.getDateDirectly(params.value,format);
    else {
      return " ";
    }
  }
 static addMonthTodate(date: any, formatInput = "DD/MM/YYYY", formatOutput = "DD/MM/YYYY") {
    return moment(date, formatInput).add("1", "M").format(formatOutput);
  }

  public getDateMoment() {
    return this.dateMoment;
  }

  static getDateMoment(format = "DD/MM/YYYY") {
    return moment(new Date()).format(format);
  }
}
